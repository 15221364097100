<template>
  <div class="body_wrap">

<!-- backtotop - start -->
<div class="backtotop">
  <a href="#" class="scroll">
    <i class="far fa-arrow-up"></i>
  </a>
</div>
<!-- backtotop - end -->

<!-- preloader - start -->
<!-- <div id="preloader"></div> -->
<!-- preloader - end -->

<!-- header_section - start
================================================== -->
<header class="header_section">
        <div class="header_middle">
          <div class="container">
            <div class="row align-items-center">
              <div class="col col-lg-3 col-5">
                <div class="brand_logo">
                  <a class="brand_link" href="/">
                    <img src="./assets/logo.png">
                  </a>
                </div>
              </div>
              <div class="col col-lg-6 col-2">
                <nav class="main_menu navbar navbar-expand-lg">
                  <div class="main_menu_inner collapse navbar-collapse justify-content-center" id="main_menu_dropdown">
                    <ul class="main_menu_list ul_li">
                      <!-- <li>
                        <a href="/">Home</a>
                      </li> -->
                      <!-- <li>
                        <a href="/register">register</a>
                      </li> -->
                      <!-- <li><a href="/register">Sign In</a></li>
                      <li class="active"><a href="/cart">Shopping Cart</a></li> -->
                      <li><a href="/#1">HOT ITEMS</a></li>
                      <li><a href="/#2">NEW ARRIVALS</a></li>
                      <li><a href="/#3">BEST SELLER</a></li>
                    </ul>
                  </div>
                </nav>
              </div>

              <div class="col col-lg-3 col-5">
                <ul class="header_icons_group ul_li_right">
                  <li>
                    <button class="mobile_menu_btn navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_menu_dropdown" aria-controls="main_menu_dropdown" aria-expanded="false" aria-label="Toggle navigation">
                      <i class="fal fa-bars"></i>
                    </button>
                  </li>
                  <li><a href="/cart"><i class="fa-thin fa-cart-shopping"></i></a></li>
                  <li><a href="/account"><i class="fa-regular fa-user"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="header_bottom">
          <div class="container">
            <div class="row align-items-center" style="padding:10px 0px;">
              <div class="col col-md-10 col-10">
                <div class="allcategories_dropdown">
                  <h2 class="title_text" style="color:#fff;"><i class="fa-duotone fa-fire"></i> Technology e-commerce, worry-free shopping!</h2>
                </div>
              </div>

              <div class="col col-md-2 col-2">
                <!-- <a href="/cart">
                <button type="button" class="cart_btn">
                  <span class="cart_icon">
                    <i class="icon icon-ShoppingCart"></i>
                  </span>
                </button>
              </a> -->
              </div>
            </div>
          </div>
        </div>
      </header>
<!-- header_section - end
================================================== -->

<!-- main body - start
================================================== -->
<main>

  <!-- sidebar cart - start
  ================================================== -->
  <div class="sidebar-menu-wrapper">
    <div class="cart_sidebar">
      <button type="button" class="close_btn"><i class="fal fa-times"></i></button>

      <ul class="cart_items_list ul_li_block mb_30 clearfix">
        <li>
          <div class="item_image">
            <img src="assets/images/cart/cart_img_1.jpg" alt="Getyootech - Gadgets Ecommerce Site Template">
          </div>
          <div class="item_content">
            <h4 class="item_title">Yellow Blouse</h4>
            <span class="item_price">MXN$30.00</span>
          </div>
          <button type="button" class="remove_btn"><i class="fal fa-trash-alt"></i></button>
        </li>
        <li>
          <div class="item_image">
            <img src="assets/images/cart/cart_img_2.jpg" alt="Getyootech - Gadgets Ecommerce Site Template">
          </div>
          <div class="item_content">
            <h4 class="item_title">Yellow Blouse</h4>
            <span class="item_price">MXN$30.00</span>
          </div>
          <button type="button" class="remove_btn"><i class="fal fa-trash-alt"></i></button>
        </li>
        <li>
          <div class="item_image">
            <img src="assets/images/cart/cart_img_3.jpg" alt="Getyootech - Gadgets Ecommerce Site Template">
          </div>
          <div class="item_content">
            <h4 class="item_title">Yellow Blouse</h4>
            <span class="item_price">MXN$30.00</span>
          </div>
          <button type="button" class="remove_btn"><i class="fal fa-trash-alt"></i></button>
        </li>
      </ul>

      <ul class="total_price ul_li_block mb_30 clearfix">
        <li>
          <span>Subtotal:</span>
          <span>MXN$90</span>
        </li>
        <li>
          <span>Vat 5%:</span>
          <span>MXN$4.5</span>
        </li>
        <li>
          <span>Discount 20%:</span>
          <span>- MXN$18.9</span>
        </li>
        <li>
          <span>Total:</span>
          <span>MXN$75.6</span>
        </li>
      </ul>

      <ul class="btns_group ul_li_block clearfix">
        <li><a class="btn btn_primary" href="/cart">View Cart</a></li>
        <li><a class="btn btn_secondary" href="checkout.html">Checkout</a></li>
      </ul>
    </div>

    <div class="cart_overlay"></div>
  </div>
  <!-- sidebar cart - end
  ================================================== -->

  <!-- breadcrumb_section - start
  ================================================== -->
  <div class="breadcrumb_section">
    <div class="container">
      <ul class="breadcrumb_nav ul_li">
        <li><a href="/">Home</a></li>
        <li>Cart</li>
      </ul>
    </div>
  </div>
  <!-- breadcrumb_section - end
  ================================================== -->

  <!-- cart_section - start
  ================================================== -->
  <section class="cart_section section_space">
    <div class="container">
      <div class="cart_update_wrap">
        <p class="mb-0"><i class="fal fa-check-square"></i> Shopping cart list</p>
      </div>

      <div class="cart_table">
        <table class="table" v-if="pageData.list.length > 0">
          <thead>
            <tr>
              <th style="text-align: center;">PRODUCT</th>
              <th class="text-center">PRICE</th>
              <th class="text-center">QUANTITY</th>
              <th class="text-center">TOTLA</th>
              <th class="text-center">REMOVE</th>
            </tr>
          </thead>
          <tbody v-if="pageData.list.length > 0">
            <tr v-for="(item, index) in pageData.list" :key="index">
              <td>
                <div class="cart_product">
                  <img style="margin:auto;" :src="'assets/images/' + item.image" alt="Getyootech - Gadgets Ecommerce Site Template">
                  <!-- <h3><a href="shop_details.html">{{ item.storeName }}</a></h3> -->
                </div>
              </td>
              <td class="text-center"><span class="price_text">MXN${{ item.price }}</span></td>
              <td class="text-center">
                <form action="#">
                  <div class="quantity_input">
                    <button type="button" class="input_number_decrement" @click="zengjia(item.id,item.productId,item.cartNum-1,item.productAttrUnique)">
                      <i class="fal fa-minus"></i>
                    </button>
                    <!-- <input class="input_number" type="text" :value="item.cartNum"> -->
                    <div style="margin:0px 10px;">{{ item.cartNum }}</div>
                    <button type="button" class="input_number_increment" @click="zengjia(item.id,item.productId,item.cartNum+1,item.productAttrUnique)">
                      <i class="fal fa-plus"></i>
                    </button>
                  </div>
                </form>
              </td>
              <td class="text-center"><span class="price_text">MXN${{ item.price }}</span></td>
              <td class="text-center"><button @click="shanchu(item.id)" type="button" class="remove_btn"><i class="fal fa-trash-alt"></i></button></td>
            </tr>
          </tbody>
        </table>
        <div v-else style="border: 1px solid #eee7e7;
    border-radius: 6px;
    padding: 20px;">
          There is no content in the cart
        </div>
      </div>
      <div class="row" v-if="pageData.list.length > 0">
        <div class="col col-lg-12">
          <div class="calculate_shipping">
            <h3 class="wrap_title">Shipping Address</h3>
            <form action="#">
              <div class="row">
                <div class="col col-md-6">
                  <div class="form_item">
                    <input v-model="province" type="text" name="firstname" placeholder="province *">
                  </div>
                </div>
                <div class="col col-md-6">
                  <div class="form_item">
                    <input v-model="city" type="text" name="lastname" placeholder="city *">
                  </div>
                </div>
                <div class="col col-md-6">
                  <div class="form_item">
                    <input v-model="district" type="text" name="companyname" placeholder="district *">
                  </div>
                </div>
                <div class="col col-md-6">
                  <div class="form_item">
                    <input v-model="Address" type="text" name="streetaddress" placeholder="Address *">
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="cart_btns_wrap" v-if="pageData.list.length > 0">
        <div class="row">
          <div class="col col-lg-6">
            <!-- <form action="#">
              <div class="coupon_form form_item mb-0">
                <input type="text" name="coupon" placeholder="Coupon Code...">
                <button type="submit" class="btn btn_primary">Apply Coupon</button>
                <div class="info_icon">
                  <i class="fas fa-info-circle" data-bs-toggle="tooltip" data-bs-placement="top" title="Your Info Here"></i>
                </div>
              </div>
            </form> -->
          </div>

          <div class="col col-lg-6">
            <ul class="btns_group ul_li_right">
              <!-- <li><a class="btn border_black" @click="gouwuche()">Update Cart</a></li> -->
              <li><a class="btn btn_dark" @click="clickOrder()">Prceed To Checkout</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- cart_section - end
  ================================================== -->

  <!-- newsletter_section - start
  ================================================== -->
  <section class="newsletter_section">
    <div class="container">
      <div class="row align-items-center">
        <div class="col col-lg-6">
          <h2 class="newsletter_title text-white">
            <i class="icofont-paper-plane"></i>
            Sign Up for Newsletter
          </h2>
        </div>
        <div class="col col-lg-6">
          <form action="#!">
            <div class="newsletter_form">
              <input type="email" name="email" placeholder="Enter your email address">
              <button type="submit" class="btn btn_danger">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- newsletter_section - end
  ================================================== -->

</main>
<!-- main body - end
================================================== -->

<!-- footer_section - start
================================================== -->
<footer class="footer_section">
        <div class="footer_widget_area">
          <div class="container">
            <div class="row">
              <div class="col col-lg-2 col-md-3 col-sm-6">
                <div class="footer_widget footer_useful_links">
                  <h3 class="footer_widget_title text-uppercase">Quick Links</h3>
                  <ul class="ul_li_block">
                    <li><a href="/register">Sign In</a></li>
                    <!-- <li><a href="/register">Sign Up</a></li> -->
                  </ul>
                </div>
              </div>

              <div class="col col-lg-2 col-md-3 col-sm-6">
                <div class="footer_widget footer_useful_links">
                  <h3 class="footer_widget_title text-uppercase">Custom area</h3>
                  <ul class="ul_li_block">
                    <li><a href="/account">My Account</a></li>
                    <li><a href="/cart">My Cart</a></li>
                  </ul>
                </div>
              </div>

              <div class="col col-lg-8 col-md-6 col-sm-12">
                <div class="footer_widget footer_useful_links">
                  <h3 class="footer_widget_title text-uppercase">contact us</h3>
                  <ul class="ul_li_block">
                    <li>Company Name： INFORMATICA GH SOLUCIONES, S.A. DE C.V.</li>
                    <li>E-mail: hungrybulo@outlook.com</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
<!-- footer_section - end
================================================== -->

</div>
</template>

<script>
import {
  apiFrontCartList, apiFrontCartResetcart, apiFrontCartDelete, apiFrontOrderCreate, apiFrontOrderPreOrder
} from '@/api/goods'
export default {
  data() {
    return {
      pageData: {},
      page: {
        isValid: true,
        limit: 100,
        page: 1
      },
      province: '',
      city: '',
      district: '',
      Address: '',
      dingdanhao: '',
      anniuShow: false
    }
  },
  mounted() {
    this.gouwuche()
  },
  methods: {
    gouwuche() {
      var data = {
        preOrderType: 'shoppingCart',
        orderDetails: []
      }
      apiFrontCartList(this.page).then(res => {
        console.log(res);
        this.pageData = res.data
        if (res.data.list.length > 0) {
          this.anniuShow = true
          for (let index = 0; index < this.pageData.list.length; index++) {
            var aa = {
              shoppingCartId: this.pageData.list[index].id
            }
            data.orderDetails.push(aa)
          }
          apiFrontOrderPreOrder(data).then(ress => {
            console.log(ress)
            this.dingdanhao = ress.data.preOrderNo
          })
        }
        
      })
    },
    zengjia(id, pid, count, unique) {
      var data = {
        id: id,
        num: count,
        productId: pid,
        unique: unique
      }
      apiFrontCartResetcart(data).then(res => {
        console.log(res);
        this.$message.success('Order Modified Successfully')
        this.gouwuche()
      })
    },
    shanchu(id) {
      apiFrontCartDelete(id).then(res => {
        console.log(res);
        this.$message.success('Order Deleted Successfully')
        this.gouwuche()
      })
    },
    clickOrder() {
      var data = {
        inputAddress: this.province + this.city + this.district + this.Address,
        payChannel: 'wowpayCheckout',
        payType: 'wowpay',
        preOrderNo: this.dingdanhao,
        shippingType: 1,
        useIntegral: false,
        mark: ''
      }
      apiFrontOrderCreate(data).then(res => {
        console.log(res);
        this.orderNoData = res.data.orderNo
        localStorage.setItem('orderNo', res.data.orderNo)
        this.$router.push('/checkout')
      })
    }
  }
}
</script>

<style>

</style>